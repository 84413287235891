import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Dialog, DialogContent, Slide, withStyles,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

interface Props {handleClose: () => void;open: boolean;videoUrl: string}

const DialogContentCustomised = withStyles({
  root: {
    padding: '0px 0px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
})(DialogContent);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}));

export const VideoPlayerModal: React.FC<Props> = ({ handleClose, open, videoUrl }) => {
  const classes = useStyles();
  const Transition = React.forwardRef((
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      fullWidth
      maxWidth="md"
    >
      <DialogContentCustomised className={classes.root}>
        <div style={{ height: 500 }}>
          <ReactPlayer
            width="100%"
            height="100%"
            controls
            playing={false}
            url={videoUrl}
          />
        </div>
      </DialogContentCustomised>
    </Dialog>
  );
};
