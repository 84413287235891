// Pion Initializations
/* eslint-disable */
// Pion Initializations
/* eslint-env browser */
import React from 'react';

import './styles.css';
import VideoPlayer from './video';

const videoJsOptions = {
  autoplay: false,
  controls: true,
  preload: true,
  // poster: '//cdnzone.nuevodevel.com/video/hls/tears/poster.jpg',
  sources: [
    {
      // src: "//cdnzone.nuevodevel.com/video/hls/tears/playlist.m3u8",
      // type: "application/x-mpegURL"
      src: "https://res.cloudinary.com/teachzy-images/video/upload/v1627377111/hirezen/videoplayback_swgzol.mp4",
    },
  ],
};

const VidePlayerScreen = () => (
  <div className="App">
    {/* <img alt="react logo" src="/assets/logo.png" height="60px" />
      <h2>Video.js + Nuevo plugin</h2>
      <h3>Loading chapters track</h3> */}
    <VideoPlayer {...videoJsOptions} />
  </div>
);

export default VidePlayerScreen;
