import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import LottieLoaderAnimation from "./LottieLoaderAnimation";

const useStyles = makeStyles({
    wrapper: {
        position: "fixed", zIndex: 1000,
        backgroundColor: 'rgba(21, 25, 44, 0.6)', height: '100%', width: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
    },
    container: {
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headingText: {
        color: 'white', fontSize: 20, marginBottom: 16
    }
});


const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <LottieLoaderAnimation/>
                <Typography className={classes.headingText} align={'center'}> Logging you in</Typography>
            </div>
        </div>
    );
};

export default Loader;
