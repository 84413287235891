import React, {useEffect, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {useServices} from "../../services";

const BarGraph = () => {

    const [optionsR, setOptions] = useState<any>(null)
    const {thirdPartyServices} = useServices();

    const fetchData = async () => {
        const data = await thirdPartyServices.fetchDataFromAirtable()
        console.log(data)
        const dates = []
        const rejected = []
        const approved = []
        data?.records.map(record => {
            dates.push(`${record.fields.Date}, ${record.fields.Time}`)
            rejected.push(parseInt(record.fields.Rejected))
            approved.push(parseInt(record.fields.Approved))
        })
        console.log("dats are", dates)

        const options = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Drop test chart'
            },
            xAxis: {
                categories: dates
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Drop test chart'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent'
                }
            },
            series: [{
                name: 'Rejected',
                data: rejected,
                color: 'red'
            }, {
                name: 'Approved',
                data: approved,
                color: 'green'
            }]
        };
        console.log(options)
        setOptions(options)
    }
    useEffect(() => {
        fetchData();
    }, [])
    //
    return (
        <div>
            {optionsR &&
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={optionsR}
                />
            }
        </div>
    )
}

export default BarGraph;
