import React, {useEffect, useState} from 'react';
import {Button, makeStyles, TextField, Typography} from '@material-ui/core';
import firebase from 'firebase/app';

import {firebaseAuth} from '../../config/firebase';

import {notifyError, notifySuccess} from '../../components/Notification';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: window.innerHeight,
    },
    textField: {
        width: 250,
        borderRadius: 16,
        marginBottom: 20,
        // border: 'solid 1px #92959e',
    },
    loginText: {
        fontSize: 34,
        fontWeight: 'bold',
        color: '#15192c',
        marginBottom: 20,
    },
    loginButton: {
        width: 250,
        borderRadius: 16,
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        backgroundColor: '#551fff',
        padding: '10px 20px',
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 12,
        '&:hover': {
            backgroundColor: '#551fff',
        }
    },
    resendCodeContainer:{
        display: 'flex', alignItems: 'center', marginTop: 16
    }
}));

const ForgetPasswordScreen = () => {
    const classes = useStyles();
    const [disabled, setIsDisabled] = useState(false);
    const [email, setEmail] = useState('');

    const sendLink = async () => {
        try {
            await firebaseAuth.sendPasswordResetEmail(email);
            notifySuccess('A link to reset password is successfully sent');
        } catch (e) {
            notifyError('Some error occured');
        }
    };


    return (
        <div className={classes.container}>
            <Typography className={classes.loginText}>
                Confirm Email
            </Typography>
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
                variant="outlined"
            />
            <Button
                className={classes.loginButton}
                onClick={() => sendLink()}
                disabled={disabled}
            >
                Send verification Code
            </Button>

            <div className={classes.resendCodeContainer}>
                <Typography style={{fontSize: 16, color: '#15192c'}}>Didn’t recieve verification
                    code?
                </Typography>
                <Typography style={{fontSize: 16, color: '#551fff', cursor: 'pointer'}} onClick={() => {
                    sendLink()
                }}>&nbsp; Resend</Typography>
            </div>


        </div>
    );
};

export default ForgetPasswordScreen;
