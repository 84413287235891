import React, {useState} from 'react';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {useServices} from "../../services";
import {useAsync} from "react-use";
import LoaderScreen from "../LoaderScreen/LoaderScreen";
import {Button} from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    divider: {
        height: 1,
        width: '80%',
        margin: 'auto',
        backgroundColor: '#e8e8e8',
    },
    searchBoxRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e5f2ff',
        borderRadius: 5,
        width: 500,
        alignSelf: 'center',
    },
    input: {
        marginLeft: 4,
        flex: 1,
        color: '#046eef',
        fontWeight: 'bold',
    },
    iconButton: {
        padding: 10,
        color: '#046eef',
    },
    filterButton: {
        padding: '10px 20px',
        borderRadius: 5,
        border: 'solid 1px #046eef',
        backgroundColor: 'white',
        color: '#046eef',
        marginRight: 10,
    },
    addDeviceButton: {
        padding: '10px 20px',
        borderRadius: 5,
        backgroundColor: '#046eef',
        color: 'white',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 'bold',
        borderBottom: '1px solid #e8e8e8',
        fontSize: 18,
        height: 70,
    },
    body: {
        fontSize: 14,
        borderBottom: 'none',
        color: '#52575c',
        height: 100,
    },
    root: {},
}))(TableCell);


const rows = [];

interface ChangeCheckBoxStateParams {
    index: number;
}

const DevicesListScreen: React.FC = () => {
    const classes = useStyles();
    const [checkBox, setCheckBox] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const {deviceService} = useServices();
    const fetchDevices = async () => {
        const data = await deviceService.fetchDeviceList('1');
        return data;
    };

    const devicesList = useAsync(async () => await fetchDevices(), []);


    const changeCheckBoxState = ({index}: ChangeCheckBoxStateParams) => {
        if (checkBox.includes(index)) {
            setCheckBox(checkBox.filter((x) => x !== index));
        } else {
            setCheckBox([...checkBox, index]);
        }
    };

    return (
        <div>
            {devicesList.loading
                ? <LoaderScreen/>
                : devicesList.error
                    ? (
                        <div>
                            Error:
                            {devicesList.error.message}
                        </div>
                    )
                    : (
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left"/>
                                        <StyledTableCell align="left">NAME</StyledTableCell>
                                        <StyledTableCell align="left">DEVICE ID</StyledTableCell>
                                        <StyledTableCell align="left">ASSIGNED TO</StyledTableCell>
                                        <StyledTableCell align="left">STATUS</StyledTableCell>
                                        <StyledTableCell align="left"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {devicesList.value.devices.map((device) => (
                                        <TableRow style={{borderBottom: 'none'}} key={Math.random().toString()}>
                                            <StyledTableCell align="left">
                                                <Checkbox
                                                    key={device.id}
                                                    checked={checkBox.includes(device.device_id)}
                                                    onClick={() => {
                                                        changeCheckBoxState({index: device.device_id});
                                                    }}
                                                    style={{borderRadius: 5}}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {device.serial_number}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{device.device_id}</StyledTableCell>
                                            <StyledTableCell align="left">{device.assigned_user_email}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Typography
                                                    style={{color: device.status ? '#2ac769' : '#e93c3c', fontSize: 14}}
                                                >
                                                    {device.is_enabled ? 'Active' : 'Inactive'}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Button> Connect </Button>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
        </div>
    );
}


export default DevicesListScreen;
