// Pion Initializations
/* eslint-disable */
// Pion Initializations
/* eslint-env browser */

import React, {useState} from 'react';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {ReportTabs} from "./components/ReportTabs";
import UplinkIcon from '../../assets/icons/uplink.svg';
import DownlinkIcon from '../../assets/icons/downlink.svg';
import ShareIcon from '../../assets/icons/reportIcons/share.svg';
import EyeIcon from '../../assets/icons/reportIcons/eye.svg';
import DownloadIcon from '../../assets/icons/reportIcons/download.svg';
import EditIcon from '../../assets/icons/reportIcons/edit.svg';
import DeleteIcon from '../../assets/icons/reportIcons/delete.svg';
import BarGraph from "../../components/BarGraph";

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'row',
        alignItems: 'center',
    },
    userImage: {
        height: 28,
        width: 28,
        marginRight: 8,
        borderRadius: 14
    },
    tableHead: {
        backgroundColor: '#f8f9fc',
        borderRadius: 10,
        border: 'none',
        opacity: 0.5
    },
    leftBorderRadius: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    rightBorderRadius: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    tableRow: {
        borderSpacing: '0px 20px',
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        borderRadius: 10,
    },
    userNameText: {
        fontSize: 16,
        color: '#15192c',
        lineHeight: 1,
        fontWeight: 500
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        borderBottom: '1px solid #e8e8e8',
        fontSize: 17,
        height: 40,
        color: '#15192c',
        backgroundColor: 'transparent',
        lineHeight: 1
    },
    body: {
        fontSize: 16,
        borderBottom: 'none',
        color: '#15192c',
        height: 40,
        backgroundColor: '#f8f9fc',
        lineHeight: 1,
        fontWeight: 500
    },
    root: {
        borderBottom: "none"
    },
}))(TableCell);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

enum EventStatus {
    ACCEPTED = 'Accepted',
    TENTAIVE = 'Tentative',
    DECLINED = 'Declined',
}

const ReportScreen: React.FC = () => {
        const classes = useStyles();

        const createData = (id, reportName, type, date, owner) => ({
            id,
            reportName,
            type,
            date,
            owner,
        });

        const rows = [
            createData(1, 'Recording Live session', 'Snag', 'null',  'Manav Dhingra'),
            createData(2, 'Design Labs session ', 'Safety', '12/04/2021 - 2:00 PM',  'Rahul Khatri' ),
            createData(3, 'Recording Live session', 'Daily', '12/04/2021 - 2:00 PM', 'Lakhan Raj'),
            createData(4, 'Recording Live session', 'Weekly', '12/04/2021 - 2:00 PM', 'Monisha Sarvi'),
            createData(5, 'Recording Live session', 'Safety', '12/04/2021 - 2:00 PM', 'Monisha Sarvi'),
            createData(6, 'Recording Live session', 'Weekly', '12/04/2021 - 2:00 PM',  'Manav Dhingra'),
        ];


        return (
            <>
                <iframe className="airtable-embed"
                        src="https://airtable.com/embed/shrsZKDqmz2R3TrlC?backgroundColor=cyan&viewControls=on"
                        frameBorder="0" width="100%" height={window.innerHeight*.4}
                        style={{background: 'transparent', border:'1px solid #ccc'}}/>
                <BarGraph/>
            </>
        );
    }
;

export default ReportScreen;
