import { apiFailureErrorMessage, HttpService } from '../utils/httpService';

export class ThirdPartyServices {
  httpService: HttpService;
  constructor( httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchDataFromAirtable() {
    const url = `https://api.airtable.com/v0/appUw7QyWmXEdSG8E/Table%201?maxRecords=300&view=Grid%20view`;
    try {
      const { data } = await this.httpService.get<any>(url, {
        headers: {
          'Authorization': 'Bearer keyF3MfNIEG9u8645'
        }
      });
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
