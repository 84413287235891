import React from 'react';

import Lottie from 'react-lottie';
import animationData from './lottieAnimation.json';


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const LottieLoaderAnimation = () => {
    return (
        <Lottie
            options={defaultOptions}
        />
    );
};

export default LottieLoaderAnimation;
