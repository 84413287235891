/* eslint-disable */
/* eslint-env browser */

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {CssBaseline, MuiThemeProvider } from '@material-ui/core';

import { ServicesProvider } from './services';
import { AuthProvider } from './context/AuthContext';
import { AppConfigDataContext } from './context';
import {theme} from './theme';
import { AppRouter } from './AppRouter';

function App() {
  return (
    <div>
      <ServicesProvider>
        <AppConfigDataContext>
          <MuiThemeProvider theme={theme}>
            <CssBaseline/>
          <AuthProvider>
            <Router>
              <AppRouter/>
            </Router>
          </AuthProvider>
          </MuiThemeProvider>
        </AppConfigDataContext>
      </ServicesProvider>
    </div>
  );
}

export default App;
