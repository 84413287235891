import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox, Grid, IconButton, InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TableHead, TablePagination,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SearchIcon from '@material-ui/icons/Search';
import $ from 'jquery';
import { callPICM3, disconnectPiCM3, websocketServerConnect } from './pion';
import { startStream } from './streaming';
import { useServices } from '../../services';
import { Device } from '../../schemas/Device';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  divider: {
    height: 1,
    width: '80%',
    margin: 'auto',
    backgroundColor: '#e8e8e8',
  },
  searchBoxRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e5f2ff',
    borderRadius: 5,
    width: 500,
    alignSelf: 'center',
  },
  input: {
    marginLeft: 4,
    flex: 1,
    color: '#046eef',
    fontWeight: 'bold',
  },
  iconButton: {
    padding: 10,
    color: '#046eef',
  },
  filterButton: {
    padding: '10px 20px',
    borderRadius: 5,
    border: 'solid 1px #046eef',
    backgroundColor: 'white',
    color: '#046eef',
    marginRight: 10,
  },
  addDeviceButton: {
    padding: '10px 20px',
    borderRadius: 5,
    backgroundColor: '#046eef',
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: 'bold',
    borderBottom: '1px solid #e8e8e8',
    fontSize: 18,
    height: 70,
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
    color: '#52575c',
    height: 100,
  },
  root: {
  },
}))(TableCell);

interface ChangeCheckBoxStateParams {
  index: number;
}

const DevicesScreen: React.FC = () => {
  const classes = useStyles();
  const [checkBox, setCheckBox] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [devices, setDevices] = useState<Device[]>([]);

  const { deviceService } = useServices();

  useEffect(() => {
    websocketServerConnect();
  }, []);
  return (
    <div>
      <Button
        onClick={() => {
          startStream();
          callPICM3();
        }}
        id="watch"
      >
        Watch
      </Button>
      <Button onClick={disconnectPiCM3}>
        Disconnect
      </Button>
      <div id="stream" />
      <div id="logs" />
    </div>
  );
};

export default DevicesScreen;
