import React from "react";
import Error404 from '../../assets/images/ErrorScreenImages/error404.png';
import SessionExpired from '../../assets/images/ErrorScreenImages/sessionExpired.png';
import SlowInternet from '../../assets/images/ErrorScreenImages/slowInternet.png';
import {Typography} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";

enum errorScreen {
    SlowInternet = 0,
    Error404 = 1,
    SessionExpired = 2,
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorImage: {
        height: 150, width: 150
    },
    headingText: {
        fontSize: 45, fontWeight: 'bold', whiteSpace: 'pre-line', marginTop: 20
    },
    detailText: {
        fontSize: 24, whiteSpace: 'pre-line', marginTop: 40
    }
}));

interface ErrorScreenProps {
    type?: errorScreen;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({type = errorScreen.SlowInternet}) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img
                src={type === errorScreen.Error404 ? Error404 : (type === errorScreen.SessionExpired ? SessionExpired : SlowInternet)}
                className={classes.errorImage}/>
            <Typography className={classes.headingText} align={'center'}>
                {type === errorScreen.Error404 ? '404 \n OHH NO!!' : (type === errorScreen.SessionExpired ? 'YOUR SESSION HAS EXPIRED' : 'WHOOPS!')}
            </Typography>
            <Typography className={classes.detailText} align={'center'}>
                {type === errorScreen.SessionExpired ? 'Please refresh the page. Don’t worry we’ve kept all your \n filters in place.' : (type === errorScreen.Error404 ? 'We’re sorry, the page you requested could not be found \n Please go back to the homepage' : 'Slow or no internet connection\nPlease check your internet settings')}
            </Typography>
        </div>
    )
}

export default ErrorScreen;
