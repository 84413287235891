import React, {
  createContext, ReactNode, useEffect, useState,
} from 'react';
// import app from '../config/firebaseCred';

export interface AuthContextSchema {
  currentUser: any;
}

interface GlobalAuthDataProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextSchema>({ currentUser: null });

export const AuthProvider = ({ children }: GlobalAuthDataProviderProps) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    // app.auth().onAuthStateChanged((user: any) => {
    //   setCurrentUser(user);
    //   setPending(false);
    // });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
