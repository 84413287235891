/* eslint-disable */
/* eslint-env browser */

import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
`;


const LoaderScreen = () => (
  <Container>
    <CircularProgress />
  </Container>
);

export default LoaderScreen;
