import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class FileService {
  httpService: HttpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchFileList() {
    const url = `${ENDPOINTS.ROOT_URL}/accounts/1/files`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }


  async takeScreenshot(timestamp: string, file_id: number, name: string) {
    const url = `${ENDPOINTS.ROOT_URL}/accounts/1/screenshot`;
    try {
      const { data } = await this.httpService.post<any>(url, {data: {
        timestamp, file_id, name
      }});
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
