import { makeServices, HttpService, TokenService } from '../utils';
import { LoginService } from './LoginService';
import { UserService } from './UserService';
import { ThirdPartyServices } from './ThirdPartyServices';
import { FileService } from './FileService';
import { DeviceService } from './DeviceService';

const tokenService = new TokenService('menmisu-token');
const httpService = new HttpService(tokenService);
const loginService = new LoginService();
const userService = new UserService(httpService);
const thirdPartyServices = new ThirdPartyServices(httpService);
const fileService = new FileService(httpService);
const deviceService = new DeviceService(httpService);

export interface Services {
    tokenService: TokenService;
    httpService: HttpService;
    loginService: LoginService;
    userService: UserService;
    thirdPartyServices: ThirdPartyServices;
    fileService: FileService;
    deviceService: DeviceService
}

export const { ServicesProvider, useServices } = makeServices<Services>({
  tokenService,
  httpService,
  loginService,
  userService,
  thirdPartyServices,
  fileService,
  deviceService,
});
