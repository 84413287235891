import axios from "axios";

const itemsDriveD = []
const uncategorisedFolders = [
    {
        id: "123",
        name: "Images",
        icon: "activefolder",
        isDirectory: true,
        expanded: true,
        items: [],

    },
    {
        id: "343",
        name: "Videos",
        icon: "activefolder",
        isDirectory: true,
        expanded: true,
        items: [],

    },
    {
        id: "3",
        name: "Audios",
        icon: "activefolder",
        isDirectory: true,
        expanded: true,
        items: [],

    }
]
const projects = [
    {
        id: "1",
        name: "Project 1",
        icon: "activefolder",
        isDirectory: true,
        expanded: true,
        items: [],
    },
    {
        id: "2",
        name: "Project 2",
        icon: "activefolder",
        isDirectory: true,
        expanded: true,
        items: [],
    }

];

const files = [{
    id: "12",
    projectId: "1",
    name: "sky 1.1",
    icon: "file",
    isDirectory: false,
    expanded: false,
    items: [],
    url: 'https://i.picsum.photos/id/857/200/300.jpg?hmac=kFf6koUaHH4bIVWuoXIIsmZJQM_9Ew5l4AOeLL2UoG8',
    type: 'IMAGE'
},
    {
        id: "1212",
        projectId: "2",
        name: "mountain 2.1",
        icon: "file",
        isDirectory: false,
        expanded: false,
        items: [],
        url: 'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
        type: 'IMAGE'
    },
]

export default {
    getItemsDriveC() {
        return projects;
    },
    getItemsDriveD() {
        return files;
    },
    getUncategorisedFolders() {
        return uncategorisedFolders
    },
    getFilesByProjectId(id) {
        return files.filter(file => file.projectId === id);
    },
    getFileByFileId(filesArg, id) {
        return filesArg.find(file => file.id === id);
    },
    getUncategorisedFiles(filesArg) {
        return filesArg.find(file => file.directory === "");
    },
    async getUncategorisedFolderNamesFromFilesArg(filesArg) {
        const filesOutput = [];
        console.log(filesArg)
        const folders = [];
        let {data} = await axios.get('https://collablens-api-lg5h2rnxja-el.a.run.app/accounts/1/folders', {
            headers: {
                'Authorization' : `Bearer BYPASS_AUTHORIZATION_a7ef6e9fb2d2145f6bac8944c929ea563e679e47632d7c939d64e871a771e730`
            }
        })
        data.folders.map(folder => {
            folders.push({
                id: Math.random().toString(),
                file_id: folder.ID,
                name: folder.path,
                icon: "activefolder",
                isDirectory: true,
                expanded: true,
                items: [],
            })
        })
        return folders
    },
    getFilesByFolderNameId(filesArg, folderName) {
        const filesOutput = [];
        console.log(filesArg)
        filesArg.map(file => {
            if (file.directory.includes(`/${folderName}`)) {
                filesOutput.push({
                    id: Math.random().toString(),
                    file_id: file.file_id,
                    name: file.name,
                    icon: "file",
                    isDirectory: false,
                    expanded: false,
                    items: [],
                    url: file.download_url
                })
            }
        })
        console.log(filesArg, folderName, filesOutput)
        return filesOutput;
    },

    async getFilesByFolderId(folderId)  {
        const filesOutput = [];
        let {data} = await axios.get(`https://collablens-api-lg5h2rnxja-el.a.run.app/accounts/1/files?folder_ids=${folderId}`, {
            headers: {
                'Authorization' : `Bearer BYPASS_AUTHORIZATION_a7ef6e9fb2d2145f6bac8944c929ea563e679e47632d7c939d64e871a771e730`
            }
        })
        if(!data.files){
            return []
        }
        data.files.map(file => {
                filesOutput.push({
                    id: Math.random().toString(),
                    file_id: file.file_id,
                    name: file.name,
                    icon: "file",
                    isDirectory: false,
                    expanded: false,
                    items: [],
                    media_type: file.media_type,
                    created_at: file.created_at,
                    url: file.download_url
                })

        })
        return filesOutput;
    },
};
