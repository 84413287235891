/* eslint-disable */
/* eslint-env browser */

import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {notifyError, notifySuccess} from '../../components/Notification';
import 'firebase/auth';
import firebase from 'firebase/app';
import {firebaseAuth} from '../../config/firebase';
import {
    IconButton,
    InputAdornment,
    InputBase,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography
} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {useServices} from '../../services';
import {useAppConfigActions} from '../../context';
import {makeStyles} from "@material-ui/core/styles";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import {validateEmail} from "../../utils/utilityFunctions";
import Loader from "../../components/Loader/Loader";

interface RouterProps {
    match: any;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: window.innerHeight,
    },
    textField: {
        width: 250,
        borderRadius: 16,
        marginBottom: 20,
        // border: 'solid 1px #92959e',
    },
    loginText: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#15192c',
        marginBottom: 8,
    },
    loginButton: {
        width: 250,
        borderRadius: 16,
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        backgroundColor: '#551fff',
        padding: '10px 20px',
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
    },
    disabledButton: {
        backgroundColor: 'grey',
    },
    headingText: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#15192c',
        lineHeight: 1,
    },
    helperText: {
        marginTop: 20,
        marginBottom: 12,
        fontSize: 24,
        color: '#15192c',
    },
    registerButton: {
        width: 250,
        borderRadius: 16,
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        backgroundColor: '#551fff',
        color: 'white',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#551fff',
        },
        height: 48,
        marginTop: 20,
        fontSize: 20
    }
}));


const SignUp = () => {
    const history = useHistory();
    let query = useQuery();
    const classes = useStyles();
    const {setUserData} = useAppConfigActions()

    // const [loading, setIsLoading] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const {userService} = useServices();

    const validateCode = async () => {
        try {
            const inviteCode = query.get('code');
            const isValid = await userService.validateInviteCode(inviteCode, email);
            return isValid;
        } catch (e) {
            notifyError('Invite code is invalid for this email');
        }
    };

    const fetchBearerTokenSignup = (signupType: string) => {
        if (firebaseAuth.currentUser) {
            const name = firebaseAuth.currentUser.displayName;
            firebaseAuth.currentUser.getIdToken(true)
                .then(async function (token) {
                    setIsLoading(false);
                    history.push('/users')
                });
        }
        setIsLoading(false);
    };

    const handleSignUp = async () => {
        setIsLoading(true)
        if(name.length === 0){
            setIsLoading(false)
            notifyError("Please enter a Name");
            return;
        }
        if(email.length === 0){
            setIsLoading(false)
            notifyError("Please enter an Email");
            return;
        }
        if(password.length === 0){
            setIsLoading(false)
            notifyError("Please enter a Password");
            return;
        }
        if(!validateEmail(email)){
            setIsLoading(false)
            notifyError("Please enter a valid Email");
            return;
        }
        try {
            const inviteCode = query.get('code');
            await userService.validateInviteCode(inviteCode, email)
                .then(res => {
                    firebaseAuth
                        .createUserWithEmailAndPassword(email, password)
                        .then(async function (res) {
                            const user: any = firebaseAuth.currentUser;
                            localStorage.setItem('email', user.email)
                            setUserData({email: user.email})
                            await userService.createUser(user.email, name, 1)
                            notifySuccess('Welcome to collablens');
                            fetchBearerTokenSignup('EMAIL');
                            setIsLoading(false)
                            return user.updateProfile({
                                displayName: name,
                            });
                        })
                        .catch(err => {
                            setIsLoading(false);
                            notifyError(err.toString());
                        });
                })
                .catch(err => {
                    setIsLoading(false);
                    notifyError('Invite code is invalid for this email');
                });
        } catch (e) {
            notifyError(e);
        }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore


    const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };


    return (
        <div className={classes.container}>
            {loading && <Loader/>}

            <Typography className={classes.headingText}>Welcome to <br/>
                Collab Lens!</Typography>
            <Typography className={classes.helperText}>Sign Up to Get Started</Typography>

            <TextField
                label="Full Name"
                id="outlined-margin-none"
                className={classes.textField}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
            />


            <TextField
                label="Email"
                id="outlined-margin-none"
                className={classes.textField}
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
            />


            <FormControl
                // className={clsx(classes.margin, classes.textField)}
                variant="outlined"
            >
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    label="Password"
                    id="outlined-adornment-password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={password}
                    style={{width: 250}}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )}
                    labelWidth={70}
                />
            </FormControl>
            <Button
                type={'submit'}
                className={classes.registerButton}
                onClick={handleSignUp}
            >
                Register
            </Button>
        </div>

    );
};

export default SignUp;
