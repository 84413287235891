export class LocalStorageHelper {
  static async setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  static async getItem(key: string) {
    return localStorage.getItem(key);
  }

  static async removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  static async clear() {
    return localStorage.clear();
  }
}
