// Pion Initializations
/* eslint-disable */
// Pion Initializations
/* eslint-env browser */

import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {useAsync} from 'react-use';
import UserImage from '../../download.png';
import {useServices} from '../../services';
import LoaderScreen from '../LoaderScreen/LoaderScreen';
import AddIcon from "@material-ui/icons/Add";
import AddUserPopup from './components/AddUserPopup';
import {Delete, PersonOutline} from "@material-ui/icons";
import {notifySuccess} from "../../components/Notification";

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'row',
        alignItems: 'center',
    },
    userImage: {
        height: 28,
        width: 28,
        marginRight: 8,
        borderRadius: 14
    },
    tableHead: {
        backgroundColor: '#f8f9fc',
        borderRadius: 10,
        border: 'none',
        opacity: 0.5
    },
    leftBorderRadius: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    rightBorderRadius: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    tableRow: {
        borderSpacing: '0px 20px',
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        borderRadius: 10,
    },
    userNameText: {
        fontSize: 16,
        color: '#15192c',
        lineHeight: 1,
        fontWeight: 500
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        borderBottom: '1px solid #e8e8e8',
        fontSize: 17,
        height: 40,
        color: '#15192c',
        backgroundColor: 'transparent',
        lineHeight: 1
    },
    body: {
        fontSize: 16,
        borderBottom: 'none',
        color: '#15192c',
        height: 40,
        backgroundColor: '#f8f9fc',
        lineHeight: 1,
        fontWeight: 500
    },
    root: {
        borderBottom: "none"
    },
}))(TableCell);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

const ManageUsersScreen: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isDeleteUserPopupOpen, setIsDeleteUserPopupOpen] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteMenuClick = () => {
        handleClose();
        setIsDeleteUserPopupOpen(true);
    }

    const handleChangeRoleMenuClick = () => {
        handleClose();
        setChangeRolePopup(true)
    }


    const [changeRolePopup, setChangeRolePopup] = useState(false);
    const {userService} = useServices();
    const fetchUsersInfo = async () => {
        const data = await userService.fetchUsers('1');
        return data;
    };

    const usersList = useAsync(async () => await fetchUsersInfo(), []);

    const [addUserPopup, setAddUserPopup] = useState(false);
    return (
        <>
            {addUserPopup && <AddUserPopup addUserPopup={addUserPopup} setAddUserPopup={setAddUserPopup} type={1}/>}
            {changeRolePopup && <AddUserPopup addUserPopup={changeRolePopup} setAddUserPopup={setChangeRolePopup} type={2}/>}
            {usersList.loading
                ? <LoaderScreen/>
                : usersList.error
                    ? (
                        <div>
                            Error:
                            {usersList.error.message}
                        </div>
                    )
                    : (
                        <TableContainer component={Paper} style={{padding: 20, minHeight: 600}}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <StyledTableCell align="left"
                                                         className={classes.leftBorderRadius}>User</StyledTableCell>
                                        <StyledTableCell align="left">Email</StyledTableCell>
                                        <StyledTableCell align="left">Role</StyledTableCell>
                                        <StyledTableCell align="left">Last Active</StyledTableCell>
                                        <StyledTableCell align="left">Device</StyledTableCell>
                                        <StyledTableCell align="left" className={classes.rightBorderRadius}/>
                                    </TableRow>
                                </TableHead>

                                <br/>

                                <TableBody style={{border: 'none',}}>
                                    {/*@ts-ignore*/}
                                    {usersList.value.users.map((user) => (
                                        <>
                                            <TableRow className={classes.tableRow}>
                                                <StyledTableCell align="left" className={classes.leftBorderRadius}>
                                                    <div className={classes.userInfoContainer}>
                                                        <Avatar src={UserImage} className={classes.userImage}/>
                                                        <Typography className={classes.userNameText}>
                                                            {user.name}
                                                        </Typography>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {user.email}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{user.role}</StyledTableCell>
                                                <StyledTableCell align="left">{user.lastActive}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {user.device}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" className={classes.rightBorderRadius}>
                                                    <IconButton onClick={handleClick}>
                                                        <MoreHorizIcon style={{color: 'grey'}}/>
                                                    </IconButton>
                                                </StyledTableCell>
                                            </TableRow>
                                            <br/>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

            <Fab color="primary" style={{position: 'fixed', right: 24, bottom: 24}}
                 onClick={() => setAddUserPopup(true)}>
                <AddIcon/>
            </Fab>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{borderRadius: 20}}
            >
                <MenuItem onClick={handleDeleteMenuClick}>
                    <Delete/>
                    <Typography style={{marginLeft: 20}}>Delete</Typography>
                </MenuItem>
                <MenuItem onClick={handleChangeRoleMenuClick}>
                    <PersonOutline/>
                    <Typography style={{marginLeft: 20}}>Change Role</Typography>
                </MenuItem>
            </Menu>


            <Dialog
                open={isDeleteUserPopupOpen}
                onClose={() => setIsDeleteUserPopupOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Rahul Budhiraja"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Are you sure you want to delete Rahul Bhudhiraja as a user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setIsDeleteUserPopupOpen(false);
                    }}>Disagree</Button>
                    <Button onClick={() => {
                        notifySuccess('User is deleted');
                        setIsDeleteUserPopupOpen(false);
                        // delete user
                    }} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ManageUsersScreen;
