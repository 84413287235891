/* eslint-disable */
/* eslint-env browser */

import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import { useServices } from '../../../services';
import { useAppConfigData } from '../../../context';
import { notifyError, notifySuccess } from '../../../components/Notification';

// @ts-ignore
const AddUserPopup = ({addUserPopup, setAddUserPopup, type}) => {
    const {userService} = useServices();
    const {user} = useAppConfigData();
    const [email , setEmail] = useState('');
    const [role, setRole] = React.useState('');

    const handleChange = (event) => {
        setRole(event.target.value);
    }

    const inviteUser = async () => {
        console.log(email);
        try{
            await userService.inviteUser(email, localStorage.getItem('email') )
            notifySuccess('An invite has been sent to '+ email)
            setAddUserPopup(false);
        }
        catch (e) {
            notifyError(e)
        }
    }

    return (
        <Dialog
            onClose={() => setAddUserPopup(false)}
            aria-labelledby="customized-dialog-title"
            open={addUserPopup}
            fullWidth={true}
            maxWidth="xs"
        >
            {/*@ts-ignore*/}
            <DialogTitle id="customized-dialog-title" onClose={() => setAddUserPopup(false)}>
                {type === 1 ? 'Invite New user' : 'Change Role'}
            </DialogTitle>
            <DialogContent style={{display:'flex', flexDirection:'column'}}>
                <TextField
                    label="Email"
                    id="outlined-margin-none"
                    style={{
                        width: 250,
                        borderRadius: 16,
                        marginBottom: 20,
                    }}
                    variant="outlined"
                    // @ts-ignore
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{width: 200}}
                    value={role}
                    label="Role"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Admin</MenuItem>
                    <MenuItem value={20}>Manager</MenuItem>
                    <MenuItem value={30}>Viewer</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => inviteUser()} color="primary">
                    {type === 1 ? 'Invite User' : 'Change Role'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddUserPopup;
