import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class DeviceService {
   httpService: HttpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  async fetchDeviceList(accountId: string) {
    const url = `${ENDPOINTS.ROOT_URL}/accounts/${accountId}/devices`;
    try {
      const { data } = await this.httpService.get<any>(url);
      return data;
    } catch (error) {
      throw new Error(apiFailureErrorMessage);
    }
  }
}
