import React, {useEffect, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const ThirdPartyGraph = () => {



    const fetchData = async () => {
        const res = await loadScript(
            "https://api.miniextensions.com/v1/iframe-embed/yqjPfqgTzNKrx8eRZYss.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fgrid-editor%2FyqjPfqgTzNKrx8eRZYss&height=860"
        );

        console.log("LOADED")
    }
    useEffect(() => {
        fetchData();
    }, [])
    //

    const loadScript = (src: any) => {
        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resovle(true);
            };

            script.onerror = () => {
                resovle(false);
            };

            document.body.appendChild(script);
        });
    };


    return (
        <div>
            <div id="miniextensions-iframe-embed-yqjPfqgTzNKrx8eRZYss">
            </div>
            {/*<script src="https://api.miniextensions.com/v1/iframe-embed/yqjPfqgTzNKrx8eRZYss.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fgrid-editor%2FyqjPfqgTzNKrx8eRZYss&height=860">*/}
            {/*</script>*/}

        </div>
    )
}

export default ThirdPartyGraph;
