import React, {useCallback, useContext, useState} from 'react';
import {withRouter, Redirect} from 'react-router';
import {useHistory} from 'react-router-dom';
import {
    Button, Divider, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import 'firebase/auth';
import firebase from 'firebase/app';
import {firebaseAuth} from '../../config/firebase';
import {notifyError} from '../../components/Notification';
import {useAppConfigActions} from '../../context';
import {validateEmail} from "../../utils/utilityFunctions";
import {useServices} from "../../services";
import Loader from "../../components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: window.innerHeight,
    },
    textField: {
        width: 250,
        borderRadius: 16,
        marginBottom: 20,
        // border: 'solid 1px #92959e',
    },
    loginText: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#15192c',
        marginBottom: 8,
    },
    loginButton: {
        width: 250,
        borderRadius: 16,
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        backgroundColor: '#551fff',
        padding: '10px 20px',
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        '&:hover': {
            backgroundColor: '#551fff',
        }
    },
    forgotPasswordText: {
        marginTop: 12,
        marginBottom: 12,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#551fff',
        cursor: 'pointer'
    }
}));

const LoginScreen = () => {
    const history = useHistory();
    const classes = useStyles();
    const [loading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const {setUserData} = useAppConfigActions();
    const {tokenService} = useServices()

    // const [isLoading, setIsLoading] = useState(false);


    const [values, setValues] = useState({
        password: '',
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };

    const handleChange = (prop: string) => (event) => {
        setValues({
            ...values,
            [prop]: event.target.value
        });
    };

    const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };

    const fetchBearerTokenSignIn = async () => {
        if (firebaseAuth.currentUser) {
            console.log(firebaseAuth.currentUser.displayName);
            firebaseAuth.currentUser.getIdToken(true)
                .then(async function (token) {
                    localStorage.setItem('email', firebaseAuth.currentUser.email);
                    localStorage.setItem('authtoken', token);
                    await tokenService.setToken(token);
                    const userJson = {email: firebaseAuth.currentUser.email}
                    setUserData(userJson);
                    localStorage.setItem('user',  JSON.stringify(userJson))
                    history.push('/users');
                    setIsLoading(false);
                });
        }
    };

    const handleLogin = async () => {
        setIsLoading(true);
        if(!validateEmail(email)){
            notifyError("Invalid Email");
            return;
        }
        if(values.password.length === 0){
            notifyError("Please enter a Password");
            return;
        }
        try {
            await firebase.auth()
                .signInWithEmailAndPassword(email, values.password);
            fetchBearerTokenSignIn();
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            notifyError(error.toString());
        }
    };

    return (
        <>
            {loading && <Loader/>}
        <div className={classes.container}>
            <div>
                <Typography className={classes.loginText}>
                    LogIn
                </Typography>
                <div>
                    <TextField
                        label="Email"
                        id="outlined-margin-none"
                        className={classes.textField}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <FormControl
                        // className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            label="Password"
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            style={{width: 250}}
                            onChange={handleChange('password')}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )}
                            labelWidth={70}
                        />
                    </FormControl>
                </div>

                <Button className={classes.loginButton} onClick={handleLogin}>
                    Login
                </Button>

                <Typography align='right' className={classes.forgotPasswordText}
                            onClick={() => history.push('/forgot-password')}>
                    Forgot password?
                </Typography>

                <Divider/>

                <Button className={classes.loginButton} style={{width: 200, marginLeft: 25}}
                        onClick={() => history.push('/signup')}>
                    Signup
                </Button>


                {/*<Typography*/}
                {/*  style={{*/}
                {/*    fontSize: 15,*/}
                {/*    cursor: 'pointer',*/}
                {/*    marginLeft: 160,*/}
                {/*    color: '#15192c',*/}
                {/*    marginTop: 8,*/}
                {/*  }}*/}
                {/*  onClick={() => history.push('/signup')}*/}
                {/*>*/}
                {/*  Signup?*/}
                {/*</Typography>*/}
            </div>
        </div>
            </>
    );
};

export default LoginScreen;
