import { LocalStorageHelper } from '../localStorage';

export class TokenService {
  tokenKey: string;
  constructor(tokenKey: string) {
    this.tokenKey = tokenKey
  }

  async getToken() {
    try {
      return await LocalStorageHelper.getItem(this.tokenKey);
    } catch (error) {
      return '';
    }
  }

  async setToken(token: string) {
    await LocalStorageHelper.setItem(this.tokenKey, token);
  }

  async clearToken() {
    await LocalStorageHelper.removeItem(this.tokenKey);
  }

  // eslint-disable-next-line class-methods-use-this
  isTokenExpired(): boolean {
    // const token = this.getTokenContent();
    //
    // if (token && token.exp) {
    //   return token.exp < Date.now() / 1000;
    // }

    return false;
  }
}
