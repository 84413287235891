/* eslint-disable */
/* eslint-env browser */

import React, {useState, useContext, ReactNode, createContext, useMemo} from 'react';

interface GlobalUserDataProviderProps {
    children: ReactNode;
}

export interface AppConfigSchema {
    loginPopupOpen: boolean;
    logoutPopupOpen: boolean;
    user: any;
    userReportCard: any;
    updateUserProfilePopupOpen: boolean;
    userFullCourseEnrollment: any
}

const initialState: AppConfigSchema = {
    loginPopupOpen: false,
    logoutPopupOpen: false,
    user: null,
    userReportCard: null,
    updateUserProfilePopupOpen: false,
    userFullCourseEnrollment: null
};

interface AppConfigActionsSchema {
    openLoginPopup: () => void;
    closeLoginPopup: () => void;
    openLogoutPopup: () => void;
    closeLogoutPopup: () => void;
    setUserData: (user: any) => void;
    setUserReportCardData: (urt: any) => void;
    openUpdateUserProfilePopup: () => void;
    closeUpdateUserProfilePopup: () => void;
    setUserFullCourseEnrollmentData: (data: any) => void;
}

const AppConfigContext = createContext<AppConfigSchema>(initialState);
const AppActionsContext = createContext<AppConfigActionsSchema | undefined>(undefined);

export const AppConfigDataContext = ({children}: GlobalUserDataProviderProps) => {
    const [loginPopupOpen, setLoginPopupOpen] = useState(false);
    const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);

    const [updateUserProfilePopupOpen, setUpdateUserProfilePopupOpen] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [userReportCard, setUserReportCard] = useState<any>(null);
    const [userFullCourseEnrollment, setUserFullCourseEnrollment] = useState<any>(null);


    const openLoginPopup = () => {
        setLoginPopupOpen(true);
    };
    const closeLoginPopup = () => {
        setLoginPopupOpen(false);
    };
    const openLogoutPopup = () => {
        setLogoutPopupOpen(true);
    };
    const closeLogoutPopup = () => {
        setLogoutPopupOpen(false);
    };
    const setUserData = (user: any) => {
        setUser(user);
    };
    const openUpdateUserProfilePopup = () => {
        setUpdateUserProfilePopupOpen(true);
    }
    const closeUpdateUserProfilePopup = () => {
        setUpdateUserProfilePopupOpen(false);
    }

    const setUserReportCardData = (urt: any) => {
        setUserReportCard(urt);
    };

    const setUserFullCourseEnrollmentData = (data: any) => {
        setUserFullCourseEnrollment(data)
    }


    const actions = useMemo(
        () => ({
            openLoginPopup,
            closeLoginPopup,
            openLogoutPopup,
            closeLogoutPopup,
            setUserData,
            openUpdateUserProfilePopup,
            closeUpdateUserProfilePopup,
            setUserReportCardData,
            setUserFullCourseEnrollmentData
        }),
        [openLoginPopup, closeLoginPopup, openLogoutPopup,
            closeLogoutPopup, setUserData, openUpdateUserProfilePopup,
            closeUpdateUserProfilePopup, setUserReportCardData, setUserFullCourseEnrollmentData]
    );

    const state = useMemo(() => {
        return {
            loginPopupOpen,
            logoutPopupOpen,
            user,
            updateUserProfilePopupOpen,
            userReportCard,
            userFullCourseEnrollment
        };
    }, [loginPopupOpen, logoutPopupOpen, user, updateUserProfilePopupOpen, userReportCard, userFullCourseEnrollment]);

    return (
        <AppConfigContext.Provider value={state}>
            <AppActionsContext.Provider value={actions}>
                {children}
            </AppActionsContext.Provider>
        </AppConfigContext.Provider>
    );
};

export function useAppConfigData() {
    const context = useContext(AppConfigContext);
    if (context === undefined) {
        throw new Error(
            'useUserData must be used within a UserDataContext.Provider',
        );
    }
    return context;
}

export function useAppConfigActions() {
    const context = useContext(AppActionsContext);
    if (context === undefined) {
        throw new Error(
            'useUserData must be used within a UserDataContext.Provider',
        );
    }
    return context;
}
