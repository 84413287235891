export const truncateText = (str: string, length: number, showDots: boolean) => (str?.length > length ? `${str.substr(0, length - 1)} ...` : str);

export const splitNameIntoFirstNameAndLastName = (name: string) => {
  const namesArray = name.split(' ');
  if (namesArray.length === 1) {
    return {
      firstName: namesArray[0],
      lastName: null,
    };
  } if (namesArray.length === 2) {
    return {
      firstName: namesArray[0],
      lastName: namesArray[1],
    };
  }
  return {
    firstName: namesArray[0],
    lastName: `${namesArray[1]} ${namesArray[2]}`,
  };
};

export const getInitialsForNamePlaceHolder = (name: string) => {
  const nameSplitted = name.split(' ');
  let returnInitials = '';
  // eslint-disable-next-line array-callback-return
  nameSplitted.map((nameIndex) => {
    returnInitials += nameIndex[0];
  });
  return returnInitials;
};

export const convertStringArrayToPascalCaseReadableFormat = (roles: string[]): string => {
  let resultString = '';
  // eslint-disable-next-line array-callback-return
  roles.map((role, index) => {
    resultString += role.charAt(0).toUpperCase() + role.slice(1);
    if (index !== roles.length - 1) {
      resultString += ', ';
    }
  });
  return resultString;
};

export const getHHMMSSTimestamp = (timestamp: string) => {
  let totalSeconds: number = parseInt(timestamp);
  let hours: any= Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes: any = Math.floor(totalSeconds / 60);
  let seconds: any = totalSeconds % 60;

  minutes = String(minutes).padStart(2, "0");
  hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");
  return(`${hours}:${minutes}:${seconds}`)
}
