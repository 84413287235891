// Pion Initializations
/* eslint-disable */
// Pion Initializations
/* eslint-env browser */

import React, {useState} from 'react';
import {
    Avatar, Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SingleUser from '../../assets/icons/singleUser.svg';
import DualUser from '../../assets/icons/DualUser.svg';
import CastIcon from '../../assets/icons/castIcon.svg';

import {useAsync} from 'react-use';
import UserImage from '../../download.png';
import {useServices} from '../../services';
import LoaderScreen from '../LoaderScreen/LoaderScreen';
import AddIcon from "@material-ui/icons/Add";
import {EventTabs} from "./EventTabs";

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'row',
        alignItems: 'center',
    },
    userImage: {
        height: 28,
        width: 28,
        marginRight: 8,
        borderRadius: 14
    },
    tableHead: {
        backgroundColor: '#f8f9fc',
        borderRadius: 10,
        border: 'none',
        opacity: 0.5
    },
    leftBorderRadius: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    rightBorderRadius: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    tableRow: {
        borderSpacing: '0px 20px',
        boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',
        borderRadius: 10,
    },
    userNameText: {
        fontSize: 16,
        color: '#15192c',
        lineHeight: 1,
        fontWeight: 500
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        borderBottom: '1px solid #e8e8e8',
        fontSize: 17,
        height: 40,
        color: '#15192c',
        backgroundColor: 'transparent',
        lineHeight: 1
    },
    body: {
        fontSize: 16,
        borderBottom: 'none',
        color: '#15192c',
        height: 40,
        backgroundColor: '#f8f9fc',
        lineHeight: 1,
        fontWeight: 500
    },
    root: {
        borderBottom: "none"
    },
}))(TableCell);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

enum EventStatus {
    ACCEPTED = 'Accepted',
    TENTAIVE = 'Tentative',
    DECLINED = 'Declined',
}

const EventsScreen: React.FC = () => {
        const classes = useStyles();

        const createData = (id, sessionName, type, date, status, organiser, device,) => ({
            id,
            sessionName,
            type,
            date,
            status,
            organiser,
            device
        });

        const rows = [
            createData(1, 'Recording Live session', 1, 'null', EventStatus.ACCEPTED, 'Manav Dhingra', 'FR670O-HFGVC'),
            createData(2, 'Design Labs session ', 2, '12/04/2021 - 2:00 PM', EventStatus.TENTAIVE, 'Rahul Khatri', 'FR670O-HFGVC'),
            createData(3, 'Recording Live session', 3, '12/04/2021 - 2:00 PM', EventStatus.DECLINED, 'Lakhan Raj', 'FR670O-HFGVC'),
            createData(4, 'Recording Live session', 1, '12/04/2021 - 2:00 PM', EventStatus.DECLINED, 'Monisha Sarvi', 'FR670O-HFGVC'),
            createData(5, 'Recording Live session', 2, '12/04/2021 - 2:00 PM', EventStatus.DECLINED, 'Monisha Sarvi', 'FR670O-HFGVC'),
            createData(6, 'Recording Live session', 1, '12/04/2021 - 2:00 PM', EventStatus.ACCEPTED, 'Manav Dhingra', 'FR670O-HFGVC'),
        ];


        return (
            <>
                <EventTabs/>
                <TableContainer component={Paper} style={{padding: 20, minHeight: 600}}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <StyledTableCell align="left"
                                                 className={classes.leftBorderRadius}>Session
                                    Name</StyledTableCell>
                                <StyledTableCell align="left">Type</StyledTableCell>
                                <StyledTableCell align="left">Date & Time</StyledTableCell>
                                <StyledTableCell align="left">Status</StyledTableCell>
                                <StyledTableCell align="left">Organiser</StyledTableCell>
                                <StyledTableCell align="left">Device</StyledTableCell>
                                <StyledTableCell align="left" className={classes.rightBorderRadius}>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <br/>

                        <TableBody style={{border: 'none',}}>
                            {/*@ts-ignore*/}
                            {rows.map((row) => (
                                <>
                                    <TableRow className={classes.tableRow}>
                                        <StyledTableCell align="left" className={classes.leftBorderRadius}>
                                            {row.sessionName}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <img
                                                src={(row.type === 1 ? SingleUser : (row.type === 2 ? DualUser : CastIcon))}
                                                height={24} width={24}/>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Typography className={classes.userNameText}
                                                        style={{color: row.date === 'null' ? '#fd2254' : '#15192c'}}>
                                                {row.date === 'null' ? 'Happening Now' : `${row.date}`}
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"
                                                         style={{color: row.status === EventStatus.ACCEPTED ? '#5ac857' : (row.status === EventStatus.TENTAIVE ? '#ff6a00' : '#fd2254')}}>{row.status}</StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.organiser}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.device}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.rightBorderRadius}>
                                            <MoreHorizIcon style={{color: 'grey'}}/>
                                        </StyledTableCell>
                                    </TableRow>
                                    <br/>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
;

export default EventsScreen;
