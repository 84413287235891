import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { TokenService } from '../token';

export const apiFailureErrorMessage = 'Failure in API call';

export class HttpService {
  tokenService: TokenService;
  constructor( tokenService: TokenService) {
    this.tokenService = tokenService
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'GET', url, ...config });
  }

  async post<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'POST', url, ...config });
  }

  async put<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PUT', url, ...config });
  }

  async patch<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PATCH', url, ...config });
  }

  async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'DELETE', url, ...config });
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const token = await this.tokenService.getToken();

    const defaultRequestInfo: AxiosRequestConfig = {
      headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
        // // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type"
      },
    };
    if (token) {
      defaultRequestInfo.headers['Authorization'] = `Bearer BYPASS_AUTHORIZATION_a7ef6e9fb2d2145f6bac8944c929ea563e679e47632d7c939d64e871a771e730`;
    }

    return axios.request<T>({
      ...defaultRequestInfo,
      ...config,
      headers: {
        ...defaultRequestInfo.headers,
        ...config.headers,
      },
    });
  }
  // onHttpErrorStatus(
  //   error: AxiosError,
  //   handleStatusCode: HandleStatusCode,
  // ): void {
  //   if (error.response && error.response.status) {
  //     const statusCode = error.response.status;
  //     const handleStatusCodeFn = handleStatusCode[statusCode];
  //
  //     if (typeof handleStatusCodeFn === 'function') {
  //       handleStatusCodeFn(error);
  //     }
  //   }
  // }
}
