/* eslint-disable */
/* eslint-env browser */

import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import {useAppConfigData} from "./context";

// @ts-ignore
const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const {user} = useAppConfigData();
    return (
        <Route
            {...rest}
            render={routeProps =>
                !!user ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect to={"/login"} />
                )
            }
        />
    );
};


export default PrivateRoute
