import {createMuiTheme} from "@material-ui/core";

const getTheme = (theme: any) => {
    return createMuiTheme({
        typography: {
            fontFamily: ['Montserrat', 'sans-serif'].join(',')
        },
        palette: {
            type: theme.paletteType,
            // grey: {
            //     800: "#000000", // overrides failed
            //     900: "#1C1D28", // overrides success, dark color which comes in header etc,
            //     700: "#15151E",
            // },
            // background: {
            //     default: theme.paletteType === 'light' ? '#000' : '#15151E',
            //     paper: '#1f1f2d'
            // },
            // primary: {
            //     main: '#ec655f'
            // },
            // secondary: {
            //     main: '#E33E7F'
            // }
        },
    });
}

export const theme = getTheme({
    paletteType: 'light',
});
