import React, { useContext } from 'react';

interface MakeServicesResponse<T> {
    ServicesProvider: React.FC<{ contextProps?: T }>;
    useServices: () => any;
    ServicesContext: React.Context<T>;
}

export function makeServices<T>(services: T): MakeServicesResponse<T> {
  /**
     * Create default (empty) services context.
     * Eslint usually will block casting an Object Literal, we are disabling this
     * for the createContext call in the schematic.
     */


    const dummy: any= {}
    const ServicesContext = React.createContext(dummy);

  /**
     * Convenience component to provide services context.
     * @param contextProps - Allows for override of injected service dependencies.
     */
  const ServicesProvider: React.FC<{ contextProps?: T }> = ({
    // eslint-disable-next-line react/prop-types
    contextProps = services,
    // eslint-disable-next-line react/prop-types
    children,
  }) => (
    <ServicesContext.Provider value={contextProps}>
      {children}
    </ServicesContext.Provider>

  );

  const useServices = (): T => useContext(ServicesContext);

  return { ServicesProvider, useServices, ServicesContext };
}
