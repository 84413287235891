import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    width: 220,
    margin: 'auto',
    backgroundColor: '#e8e8e8',
  },
}));

const NavigationDivider:React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.divider} />
  );
};

export default NavigationDivider;
