import {ENDPOINTS} from '../config';
import {apiFailureErrorMessage, HttpService} from '../utils';

export class UserService {
    httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async fetchUsers(accountId: string) {
        const url = `${ENDPOINTS.ROOT_URL}/accounts/${accountId}/users`;
        try {
            const {data} = await this.httpService.get<any>(url);
            return data;
        } catch (error) {
            console.log(error)
            throw new Error(apiFailureErrorMessage);
        }
    }

    async validateInviteCode(inviteCode: string, email: string) {
        const url = `${ENDPOINTS.ROOT_URL}/invite/${inviteCode}?email=${email}`;
        try {
            const {data} = await this.httpService.get<any>(url);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async createUser(email: string, name: string, account_id: number, role: string = 'collaborator', phone: string = "+15128318157") {
        const url = `${ENDPOINTS.ROOT_URL}/users`;
        try {
            const {data} = await this.httpService.post<any>(url, {
                data: {
                    name, email, account_id, role, phone
                }
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async inviteUser(userEmail: string, selfEmail: string) {
        const url = `${ENDPOINTS.ROOT_URL}/invites/1`;
        try {
            const {data} = await this.httpService.post<any>(url, {
                data: {
                    to_email: userEmail,
                    from_email: selfEmail
                }
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }
}
