import React from "react";
import TreeView from "devextreme-react/tree-view";
import Sortable from "devextreme-react/sortable";

import service from "./data.js";
import axios from "axios";
import {Button, Typography} from "@material-ui/core";
import VideoPlayer from "../VideoPlayerScreen/video";
import {getHHMMSSTimestamp} from "../../utils";
import {notifySuccess} from "../../components/Notification";

class FilesScreen extends React.Component {
    constructor(props) {
        super(props);
        // @ts-ignore
        this.treeViewDriveCRef = React.createRef();
        // @ts-ignore
        this.treeViewDriveDRef = React.createRef();
        // @ts-ignore
        this.treeViewDriveUncategorised = React.createRef();

        this.state = {
            itemsDriveC: [],
            uncategorisedFolders: service.getUncategorisedFolders(),
            itemsDriveD: [],
            filesItems: [],
            selectedProjectId: null,
            selectedFiles: [],
            selectedFileId: null,
            selectedFile: null,
            files: [],
            currentTimestampForScreenshot: null
        };

        this.onDragChange = this.onDragChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    getData = async () => {
        let {data} = await axios.get('https://collablens-api-lg5h2rnxja-el.a.run.app/accounts/1/files', {
            headers: {
                'Authorization' : `Bearer BYPASS_AUTHORIZATION_a7ef6e9fb2d2145f6bac8944c929ea563e679e47632d7c939d64e871a771e730`
            }
        })
        console.log("MOUNTED  !!", data.files)
        const folders = await service.getUncategorisedFolderNamesFromFilesArg(data.files)
        this.setState({
            files: data.files,
            itemsDriveC: folders
        })
    }

    async componentWillMount() {
        await this.getData();
    }

    moveFile = async (fileID, newDirectory) => {
        let {data} = await axios.put(`https://collablens-api-lg5h2rnxja-el.a.run.app/accounts/1/files/${fileID}`, {
            directory: `${newDirectory}`
        })
        await this.getData();
    }

    render() {
        // @ts-ignore
        return (
            <div className="form">
                <div className="drive-panel">

                    <div className="drive-header dx-treeview-item">
                        <div className="dx-treeview-item-content" style={{marginBottom: 20}}>
                            <i className="dx-icon dx-icon-activefolder"></i>
                            <span>Uncategorised</span>
                        </div>
                    </div>
                    <Sortable
                        filter=".dx-treeview-item"
                        group="shared"
                        data="uncategorised"
                        allowDropInsideItem={true}
                        allowReordering={true}
                        onDragChange={this.onDragChange}
                        onDragEnd={this.onDragEnd}
                    >
                        <TreeView
                            id="treeviewDriveUncategorised"
                            expandNodesRecursive={false}
                            dataStructure="tree"
                            // ref={this.treeViewDriveUncategorised}
                            items={this.state.uncategorisedFolders}
                            width={250}
                            height={380}
                            displayExpr="name"
                            onItemClick={(e) => {
                                console.log(e.itemData.name, service.getFilesByProjectId(e.itemData.id))
                                this.setState({
                                    selectedProjectId: e.itemData.id,
                                    filesItems: service.getFilesByFolderNameId(this.state.files, e.itemData.name)
                                })
                            }}
                            // render={<p> Hello </p>}
                        />
                    </Sortable>


                    <div className="drive-header dx-treeview-item">
                        <div className="dx-treeview-item-content" style={{marginBottom: 20}}>
                            <i className="dx-icon dx-icon-activefolder"></i>
                            <span>Projects</span>
                        </div>
                    </div>
                    <Sortable
                        filter=".dx-treeview-item"
                        group="shared"
                        data="projects"
                        allowDropInsideItem={true}
                        allowReordering={true}
                        onDragChange={this.onDragChange}
                        onDragEnd={this.onDragEnd}
                    >
                        <TreeView
                            id="treeviewDriveC"
                            expandNodesRecursive={false}
                            dataStructure="tree"
                            // ref={this.treeViewDriveCRef}
                            items={this.state.itemsDriveC}
                            width={250}
                            height={380}
                            displayExpr="name"
                            onItemClick={(e) => {
                                service.getFilesByFolderId(e.itemData.file_id).then(files => {
                                    this.setState({
                                        selectedProjectId: e.itemData.id,
                                        filesItems: files
                                    })
                                })
                            }}
                        />
                    </Sortable>
                </div>

                <div className="drive-panel">
                    <div className="drive-header dx-treeview-item">
                        <div className="dx-treeview-item-content" style={{marginBottom: 20}}>
                            <i className="dx-icon dx-icon-activefolder"></i>
                            <span>Files</span>
                        </div>
                    </div>
                    <Sortable
                        filter=".dx-treeview-item"
                        group="shared"
                        // data="driveC"
                        allowDropInsideItem={true}
                        allowReordering={true}
                        onDragChange={this.onDragChange}
                        onDragEnd={this.onDragEnd}
                    >
                        <TreeView
                            id="treeviewDrived"
                            expandNodesRecursive={false}
                            dataStructure="tree"
                            ref={this.treeViewDriveDRef}
                            items={this.state.filesItems}
                            width={250}
                            height={'100%'}
                            displayExpr="name"
                            onItemClick={(e) => {
                                console.log(e.itemData)
                                this.setState({
                                    selectedFileId: e.itemData.id,
                                    selectedFile: e.itemData,
                                })

                            }}
                        />
                    </Sortable>
                </div>

                <div className="drive-panel" style={{width: '100%'}}>
                    <div className="drive-header dx-treeview-item">
                        <div className="dx-treeview-item-content" style={{marginBottom: 20}}>
                            <i className="dx-icon dx-icon-activefolder"></i>
                            <span>Preview</span>
                        </div>
                    </div>
                    {this.state.selectedFile === null && <p> No file selected </p>}
                    {this.state.selectedFile &&
                    <>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12}}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection:'column'
                            }}>
                                {this.state.selectedFile?.media_type === 'image' && <img src={this.state.selectedFile?.url} height={300}/>}
                                {this.state.selectedFile?.media_type === 'video' &&
                                <>
                                <VideoPlayer {...{
                                    autoplay: false,
                                    controls: true,
                                    preload: true,
                                    // poster: '//cdnzone.nuevodevel.com/video/hls/tears/poster.jpg',
                                    sources: [
                                        {
                                            // src: "//cdnzone.nuevodevel.com/video/hls/tears/playlist.m3u8",
                                            type: "video/mp4",
                                            src: this.state.selectedFile?.url
                                        },
                                    ],
                                }
                                }
                                    takeScreenshot={ async (t) => {
                                        notifySuccess("Screenshot taken")
                                        await axios.post(`https://collablens-api-lg5h2rnxja-el.a.run.app/accounts/1/screenshot`, {
                                            timestamp: getHHMMSSTimestamp(t),
                                            file_id: this.state.selectedFile?.file_id,
                                            name: `Screenshot-${Math.random().toString()}`
                                        })
                                    }}
                                />
                                {/*<Button style={{*/}
                                {/*    width: 250,*/}
                                {/*    borderRadius: 16,*/}
                                {/*    boxShadow: '4px 4px 5px 0 rgba(229, 229, 229, 0.63)',*/}
                                {/*    backgroundColor: '#551fff',*/}
                                {/*    padding: '10px 20px',*/}
                                {/*    color: 'white',*/}
                                {/*    textTransform: 'none',*/}
                                {/*    fontSize: 18,*/}
                                {/*    fontWeight: 'bold',*/}
                                {/*    marginTop: 35,*/}
                                {/*    '&:hover': {*/}
                                {/*        backgroundColor: '#551fff',*/}
                                {/*    }*/}
                                {/*}}>*/}
                                {/*    Take Screenshot*/}
                                {/*</Button>*/}
                                    </>
                                }

                            </div>

                        </div>
                        <div style={{marginTop: 50}}>
                            <Typography
                                style={{fontSize: 18, fontWeight: 'bold'}}>{this.state.selectedFile.name}</Typography>
                            <Typography style={{fontSize: 15, fontWeight: '500'}}>{this.state.selectedFile.media_type.toUpperCase()}</Typography>
                            <Typography style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginTop: 12,
                                marginBottom: 12
                            }}>Information</Typography>
                            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                <Typography style={{fontSize: 12, fontWeight: '600', color: 'grey'}}>
                                    Created At
                                </Typography>
                                <Typography style={{fontSize: 12, fontWeight: 'bold'}}>{this.state.selectedFile.created_at}</Typography>
                            </div>
                        </div>
                    </>}

                </div>


            </div>

        );
    }

    get treeViewDriveC() {
        return this.treeViewDriveCRef.current.instance;
    }

    get treeViewDriveD() {
        return this.treeViewDriveDRef.current.instance;
    }

    onDragChange(e) {
        if (e.fromComponent === e.toComponent) {
            const fromNode = this.findNode(this.getTreeView(e.fromData), e.fromIndex);
            const toNode = this.findNode(
                this.getTreeView(e.toData),
                this.calculateToIndex(e)
            );
            if (toNode !== null && this.isChildNode(fromNode, toNode)) {
                e.cancel = true;
            }
        }
    }

    onDragEnd(e) {
        if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
            return;
        }

        const fromTreeView = this.getTreeView(e.fromData);
        const toTreeView = this.getTreeView(e.toData);

        const fromNode = this.findNode(fromTreeView, e.fromIndex);
        const toNode = this.findNode(toTreeView, this.calculateToIndex(e));

        // if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
        //     return;
        // }

        const fromTopVisibleNode = this.getTopVisibleNode(e.fromComponent);
        const toTopVisibleNode = this.getTopVisibleNode(e.toComponent);

        const fromItems = this.state[this.getStateFieldName(e.fromData)];
        const toItems = this.state[this.getStateFieldName(e.toData)];
        console.log(e, 'moved', fromNode, e.fromData, e.toData, toNode)
        console.log(this.state.filesItems)
        this.moveFile(fromNode.itemData.file_id, this.state.itemsDriveC[e.toIndex].name)
        this.setState((state) => {
            return {filesItems: state.filesItems.filter(file => file.file_id !== fromNode.itemData.file_id)};
        });
        // this.moveNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);
        //
        // this.setState({
        //     [this.getStateFieldName(e.fromData)]: [...fromItems],
        //     [this.getStateFieldName(e.toData)]: [...toItems]
        // });
        fromTreeView.scrollToItem(fromTopVisibleNode);
        toTreeView.scrollToItem(toTopVisibleNode);
    }

    getTreeView(driveName) {
        return driveName === "driveC" ? this.treeViewDriveC : this.treeViewDriveD;
    }

    getStateFieldName(driveName) {
        return driveName;
    }

    calculateToIndex(e) {
        if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
            return e.toIndex;
        }

        return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
    }

    findNode(treeView, index) {
        const nodeElement = treeView
            .element()
            .querySelectorAll(".dx-treeview-node")[index];
        if (nodeElement) {
            return this.findNodeById(
                treeView.getNodes(),
                nodeElement.getAttribute("data-item-id")
            );
        }
        return null;
    }

    findNodeById(nodes, id) {
        for (let i = 0; i < nodes.length; i += 1) {
            if (nodes[i].itemData.id === id) {
                return nodes[i];
            }
            if (nodes[i].children) {
                const node = this.findNodeById(nodes[i].children, id);
                if (node != null) {
                    return node;
                }
            }
        }
        return null;
    }

    moveNode(fromNode, toNode, fromItems, toItems, isDropInsideItem) {
        const fromNodeContainingArray = this.getNodeContainingArray(
            fromNode,
            fromItems
        );
        const fromIndex = fromNodeContainingArray.findIndex(
            (item) => item.id === fromNode.itemData.id
        );
        fromNodeContainingArray.splice(fromIndex, 1);

        if (isDropInsideItem) {
            toNode.itemData.items.splice(
                toNode.itemData.items.length,
                0,
                fromNode.itemData
            );
        } else {
            const toNodeContainingArray = this.getNodeContainingArray(
                toNode,
                toItems
            );
            const toIndex =
                toNode === null
                    ? toNodeContainingArray.length
                    : toNodeContainingArray.findIndex(
                        (item) => item.id === toNode.itemData.id
                    );
            toNodeContainingArray.splice(toIndex, 0, fromNode.itemData);
        }
    }

    getNodeContainingArray(node, rootArray) {
        return node === null || node.parent === null
            ? rootArray
            : node.parent.itemData.items;
    }

    isChildNode(parentNode, childNode) {
        let {parent} = childNode;
        while (parent !== null) {
            if (parent.itemData.id === parentNode.itemData.id) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    getTopVisibleNode(component) {
        const treeViewElement = component.element();
        const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
        const nodes = treeViewElement.querySelectorAll(".dx-treeview-node");
        for (let i = 0; i < nodes.length; i += 1) {
            const nodeTopPosition = nodes[i].getBoundingClientRect().top;
            if (nodeTopPosition >= treeViewTopPosition) {
                return nodes[i];
            }
        }

        return null;
    }
}

export default FilesScreen;
